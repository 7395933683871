import '../CSS/ConnectorProcessCharts.css'
import { ReactiveComponent, ReactiveChart } from '@appbaseio/reactivesearch'
import ReactApexChart from 'react-apexcharts';
import { map, get } from "lodash";
import SourceMappings from '../Constants/SourceMappings';

function formatValueWithCommas(value) {
    if (value >= 1e9) {
        return (value / 1e9).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') + 'B';
    } else if (value >= 1e6) {
        return (value / 1e6).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') + 'M';
    } else if (value >= 1e3) {
        return (value / 1e3).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') + 'K';
    } else {
        return value.toFixed(0).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    }
}

const getRandomColors = (size) => {
    const colors = ['#0e7329', '#db6714', '#231cb8', '#c7141d', '#31f505', '#515252', '#CC6677', '#882255', '#AA4499'];

    for (let i = 0; i < size; i++) {
        const color = '#' + Math.floor(Math.random() * 16777215).toString(16);
        colors.push(color);
    }

    return colors;
};

const colors = getRandomColors(30);

const getColumnChartOptions = (dataKeys, barColor) => {
    const BarChartOptions = {
        chart: {
            type: 'bar',
            height: 350
        },
        colors: [barColor],
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: false,
            }
        },
        dataLabels: {
            enabled: false
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
        },
        xaxis: {
            categories: dataKeys,
            position: 'bottom',
            labels: {
                rotate: -60,
                showDuplicates: false,
                trim: true,
            },
            tickAmount: 6
        },
        tooltip: {
            enabled: true,
            shared: false,
            trigger: 'axis',
        },
        legend: {
            position: 'top',
            horizontalAlign: 'center',
        },
    }
    return BarChartOptions;
};

const getCountColumnChartOptions = (dataKeys) => {
    const BarChartOptions = {
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: true,
            },
        },
        colors: ['#F82727', '#219621'],
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded',
                borderRadius: 4
            },
        },
        dataLabels: {
            enabled: false,
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5,
            },
        },
        xaxis: {
            categories: dataKeys,
            position: 'bottom',
            labels: {
                rotate: -45,
                showDuplicates: false,
                trim: true,
            },
            tickAmount: 6,
            min: (dataKeys[0]),
            max: dataKeys[dataKeys.length - 1],
        },
        yaxis: {
            title: {
                text: 'Count',
            },
        },
        tooltip: {
            enabled: true,
            shared: false,
            trigger: 'axis',
        },
        legend: {
            position: 'top',
            horizontalAlign: 'center',
        },
    };
    return BarChartOptions;
};

const ConnectorColumnChart = ({ componentId, datafield, interactiveIds, color }) => {
    let aggregation = {
        data: {
            terms: {
                field: datafield,
                size: 100
            }
        }
    };
    return (
        <ReactiveComponent
            componentId={componentId}
            defaultQuery={() => ({
                query: {
                    match_all: {},
                },
                aggs: aggregation,
                size: 0,
            })
            }
            showFilter
            react={interactiveIds}
            render={({ aggregations }) => {
                let datakeys = [];
                let values = [];
                map(
                    get(aggregations, 'data.buckets'),
                    (b) => {
                        datakeys.push(SourceMappings[b.key] ? SourceMappings[b.key] : b.key);
                        values.push(b.doc_count);
                    }
                );
                const barchartOptions = getColumnChartOptions(datakeys, color);
                return (
                    <div className='chart'>
                        <ReactApexChart options={barchartOptions} series={[{ data: values }]} type="bar" height={450} />
                        <hr />
                    </div>
                );
            }}
        />

    )
}

const IndexRunColumnChart = ({ componentId, interactiveIds }) => {
    return (
        <ReactiveComponent
            componentId={componentId}
            defaultQuery={() => ({
                query: {
                    match_all: {},
                },
                aggs: {
                    data: {
                        date_histogram: {
                            field: '@timestamp',
                            calendar_interval: '1w',
                            format: 'yyyy.MM.dd'
                        },
                        aggs: {
                            status: {
                                terms: {
                                    field: 'data.reindex.result.meta.meta.connection.status.keyword',
                                    size: 10
                                }
                            }
                        }
                    }
                },
                size: 0,
            })
            }
            showFilter
            react={interactiveIds}
            render={({ aggregations }) => {
                let datakeys = [];
                let pass_values = [];
                let fail_values = []
                map(
                    get(aggregations, 'data.buckets'),
                    (b) => {
                        let day_count = b.doc_count;
                        let fail_count = 0;
                        let pass_count = day_count;
                        if (b.status.buckets.length > 0) {
                            fail_count = fail_count + b.status.buckets[0].doc_count;
                            pass_count = day_count - fail_count;
                        }
                        pass_values.push(pass_count);
                        fail_values.push(fail_count);
                        datakeys.push(b.key_as_string);
                    }
                );
                const barchartOptions = getCountColumnChartOptions(datakeys);
                const dataSeries = [{ name: 'Fail', data: fail_values }, { name: 'Pass', data: pass_values }];
                if (dataSeries.length === 0) {
                    return (
                        <div>
                            Chart Loading...
                        </div>
                    );
                }

                return (
                    <div className='chart'>
                        <ReactApexChart options={barchartOptions} series={dataSeries} type="bar" height={450} />
                        <hr />
                    </div>
                );
            }}
        />

    )
}

const ReactiveColumnChart = ({ componentId, interactiveIds }) => {
    return (
        <ReactiveChart
            componentId={componentId}
            title="Custom Chart"
            useAsFilter={false}
            chartType="bar"
            dataField='@timestamp'
            defaultQuery={() => ({
                query: {
                    match_all: {},
                },
                aggs: {
                    data: {
                        date_histogram: {
                            field: '@timestamp',
                            calendar_interval: '1w',
                            format: 'yyyy-MM-dd hh:mm',
                        },
                        aggs: {
                            updated_documents: {
                                sum: {
                                    field: 'data.reindex.result.created'
                                }
                            }
                        }
                    },
                },
            })}
            react={interactiveIds}
            URLParams
            setOption={({ rawData }) => {
                const buckets = (rawData && rawData.aggregations && rawData.aggregations.data.buckets) || [];

                // Function to format values with commas for K, M, B


                return {
                    tooltip: {
                        trigger: 'axis', // Show tooltips when hovering over bars
                        axisPointer: {
                            type: 'shadow', // Display a shadow to indicate the axis pointer
                        },
                        formatter: function (params) {
                            const formattedValue = formatValueWithCommas(params[0].value);
                            return (
                                '<span style="color: #3d85c6; font-size: 12px;">●</span> ' +
                                '<strong>' +
                                params[0].name +
                                '</strong>: ' +
                                formattedValue
                            );
                        },
                    },
                    grid: {
                        left: '3%', // Adjust the left margin
                        right: '4%', // Adjust the right margin
                        bottom: '3%', // Adjust the bottom margin
                        containLabel: true,
                        backgroundColor: [
                            'white', // White background for the even rows
                            '#f0f0f0', // Light grey background for the odd rows
                        ],
                    },
                    xAxis: {
                        type: 'category',
                        data: buckets.map(bucket => bucket.key_as_string),
                        axisTick: {
                            alignWithLabel: true,
                        },
                    },
                    yAxis: {
                        type: 'value',
                    },
                    series: [
                        {
                            type: 'bar',
                            barWidth: '50%', // Adjust bar width
                            itemStyle: {
                                color: '#3d85c6', // Change bar color
                                barBorderRadius: [3, 3, 0, 0],
                            },
                            label: {
                                show: false
                            },
                            data: buckets.map(bucket => bucket.updated_documents.value),
                        },
                    ],
                };
            }}

        />
    )
}

const DataTable = ({ classTitle, componentId, datafield, interactiveIds, title }) => {
    return (
        <ReactiveComponent
            componentId={componentId}
            defaultQuery={() => ({
                query: {
                    bool: {
                        must: [
                            {
                                exists: {
                                    field: 'data.reindex.result.updated',
                                },
                            },
                        ],
                    },
                },
                aggs: {
                    data: {
                        terms: {
                            field: datafield,
                            size: 100,
                            order: { _key: 'asc' }
                        },
                        aggs: {
                            updated_docs: {
                                sum: {
                                    field: 'data.reindex.result.created'
                                }
                            }
                        }
                    }
                },
                size: 0,
            })
            }
            showFilter
            react={interactiveIds}
            render={({ aggregations }) => {
                let datakeys = [];
                let values = [];
                map(
                    get(aggregations, 'data.buckets'),
                    (b) => {
                        datakeys.push(SourceMappings[b.key] ? SourceMappings[b.key] : b.key);
                        values.push(formatValueWithCommas(b.updated_docs.value));
                    }
                );
                return (
                    <div className={classTitle}>
                        <table>
                            <thead>
                                <tr>
                                    <th>{title}</th>
                                    <th>Document Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.from({ length: datakeys.length }, (_, index) => (
                                    <tr key={index}>
                                        <td>{datakeys[index]}</td>
                                        <td>{values[index]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                );
            }}
        />
    )
}

const ReactivePieChart = ({ componentId, interactiveIds }) => {
    return (
        <ReactiveChart
            // To customize the query to fetch chart data
            componentId={componentId}
            title="Pie Chart"
            useAsFilter={false}
            chartType="pie"
            dataField='data.reindex.result.meta.statusCode'
            defaultQuery={() => ({
                query: {
                    match_all: {},
                },
                aggs: {
                    data: {
                        terms: {
                            field: 'data.reindex.result.meta.statusCode',
                            size: 30,
                        },
                    },
                },
            })}
            react={interactiveIds}
            URLParams
            // Customize the chart UI, set custom echart option
            setOption={({ rawData }) => {
                const buckets = (rawData && rawData.aggregations && rawData.aggregations.data.buckets) || [];
                return (
                    {
                        tooltip: {
                            trigger: 'item',
                        },
                        legend: {
                            orient: 'vertical',
                            left: 'left',
                        },
                        series: [
                            {
                                name: 'Error Code Disctibution',
                                type: 'pie',
                                radius: '50%',
                                data: buckets.map(item => ({
                                    value: item.doc_count,
                                    name: item.key,
                                })),
                                emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                                    },
                                },
                            },
                        ],
                    })
            }
            }
        />
    )
}

const ReactiveLineChartRelative = ({ componentId, interactiveIds }) => {
    return (
        <ReactiveChart
            className="custom-chart"
            style={{ height: '100%', width: '100%', backgroundColor: '#C5DAEE' }}
            componentId={componentId}
            title="Reactive Chart"
            dataField="@timestamp"
            chartType="line"
            URLParams
            showFilter
            useAsFilter={false}
            react={interactiveIds}
            defaultQuery={() => ({
                query: {
                    match_all: {},
                },
                aggs: {
                    timestampAggs: {
                        date_histogram: {
                            field: '@timestamp',
                            calendar_interval: '1w',
                            format: 'yyyy-MM-dd hh:mm',
                        },
                        aggs: {
                            sourcesAggs: {
                                terms: {
                                    field: 'data.reindex.source.keyword',
                                    size: 100
                                }, aggs: {
                                    createdDocs: {
                                        sum: {
                                            field: 'data.reindex.result.created'
                                        }
                                    }
                                }
                            },
                        },
                    },
                },
            })}
            setOption={({ rawData }) => {
                if (!rawData || !rawData.aggregations) {
                    return {}; // Handle no data scenario
                }

                const aggs = rawData.aggregations;
                const timestampSourcesTable = {};
                const sourcesTimestampTable = {};
                aggs.timestampAggs.buckets.forEach((timestampBucket) => {
                    const timestamp_dt = timestampBucket.key_as_string;
                    const sourcesAggs = timestampBucket.sourcesAggs.buckets;
                    const sourcesData = {};
                    sourcesAggs.forEach((sourcesBucket) => {
                        const source = SourceMappings[sourcesBucket.key] ? SourceMappings[sourcesBucket.key] : sourcesBucket.key;
                        sourcesData[source] = sourcesBucket.createdDocs.value.toFixed(0);
                    });
                    timestampSourcesTable[timestamp_dt] = sourcesData;
                    sourcesAggs.forEach((sourcesBucket) => {
                        const source = SourceMappings[sourcesBucket.key] ? SourceMappings[sourcesBucket.key] : sourcesBucket.key;
                        if (!sourcesTimestampTable[source]) {
                            sourcesTimestampTable[source] = {};
                        }
                        sourcesTimestampTable[source][timestamp_dt] = sourcesData[source];
                    });
                });
                return {
                    legend: null,
                    xAxis: {
                        data: Object.keys(timestampSourcesTable).sort(),
                    },
                    yAxis: {},
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'line',
                            axis: 'x',
                        },
                        formatter: (params) => {
                            params.sort((a, b) => b.value - a.value);
                            let tooltip = '<b>' + params[0].name + '</b><br>';
                            params.forEach((param) => {
                                if (param.value > 0) { // Exclude entries where the value is zero
                                    const dot = `<span style="display:inline-block; width:10px; height:10px; border-radius:50%; background-color:${param.color}; margin-right:6px;"></span>`;
                                    const formattedValue = param.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
                                    tooltip += `${dot}<b>${param.seriesName}:</b> ${formattedValue}<br>`;
                                }
                            });

                            return tooltip;
                        },
                    },
                    series: Object.keys(sourcesTimestampTable).map((lot_number, index) => ({
                        data: Object.keys(timestampSourcesTable)
                            .sort()
                            .map(timestamp_dt => {
                                const value = sourcesTimestampTable[lot_number][timestamp_dt];
                                return isNaN(value) ? 0 : value;
                            }), // Handle missing data,
                        connectNulls: true,
                        type: 'line',
                        name: lot_number,
                        itemStyle: {
                            color: colors[index % colors.length],
                        },
                        showLegendSymbol: false,
                    })),
                };
            }}
        />
    )
}

const ConnectorProcessCharts = ({ interactiveIds }) => {
    return (
        <div>
            <div className="row g-2">
                <div className="col-12">
                    <h6 className='h6'>New Documents Over Time</h6>
                    <ReactiveColumnChart componentId="reactive_column_chart" interactiveIds={interactiveIds} />
                </div>
            </div>
            <hr />
            <div className='row g-2'>
                <div className='col-12'>
                    <h6 className='h6'>Weekly New Documents Across Sources</h6>
                    <ReactiveLineChartRelative
                        componentId="relativeLineChartCount"
                        interactiveIds={interactiveIds}
                    />
                </div>
            </div>
            <hr />

            <div className="row g-2">
                <div className="col-12">
                    <h6 className='h6'>Indexing Runs Over Time</h6>
                    <IndexRunColumnChart componentId='Index-Runs-Over-Time' interactiveIds={interactiveIds} />
                </div>
            </div>
            <hr />
            <div className="row g-2">
                <div className="col-6">
                    <h6 className='h6'>Indexing Runs by Source Index</h6>
                    <ConnectorColumnChart componentId="column-chart-data-source" interactiveIds={interactiveIds} datafield='data.reindex.source.keyword' color='#0D6ABF' />
                </div>
                <div className="col-6">
                    <h6 className='h6'>Indexing Runs by Target Index</h6>
                    <ConnectorColumnChart componentId="column-chart-data-target" interactiveIds={interactiveIds} datafield='data.reindex.target.keyword' color='#E07C3E' />
                </div>
            </div>
            <div className="row g-2">

                <div className="col-5">
                    <h6>Document count over Source Index</h6>
                    <DataTable classTitle='data-table-source' interactiveIds={interactiveIds} componentId='data-table-source' datafield='data.reindex.source.keyword' title='Data Source' />
                </div>

                <div className="col-5">
                    <h6>Document count over Target Index</h6>
                    <DataTable classTitle='data-table-target' interactiveIds={interactiveIds} componentId='data-table-target' datafield='data.reindex.target.keyword' title='Target Index' />
                </div>
                <div className='col-2'>
                    <h6>Error Code Distribution</h6>
                    <ReactivePieChart componentId='reactive-pie-chart' interactiveIds={interactiveIds} />
                </div>
            </div>
        </div>
    )
}

export default ConnectorProcessCharts;