import React from 'react';
import {
  SelectedFilters,
  MultiList,
  DynamicRangeSlider,
  ReactiveBase,
  DateRange,
  ReactiveComponent
} from '@appbaseio/reactivesearch';
import { map, get, without } from 'lodash';
import dateformat from 'dateformat';
import { useReactiveProps } from '../ReactiveProps';
import { Tabs, Tab, Spinner } from 'react-bootstrap';
import ErrorBoundary from '../components/ErrorBoundary';
import theme from './ReactiveBaseTheme';
import ConnectorProcessPassedDetails from '../components/Groups/Pages/ConnectorProcessPassedDetails';
import ConnectorProcessCharts from '../components/Groups/Pages/ConnectorProcessCharts';
import ConnectorProcessFailedDetails from '../components/Groups/Pages/ConnectorProcessFailedDetails';
import ConnectorProcessInactiveDetails from '../components/Groups/Pages/ConnectorProcessInactiveDetails';
import '../components/Groups/CSS/ConnectorProcess.css'
import SourceMappings from '../components/Groups/Constants/SourceMappings';

const interactiveIds = {
  and: [
    'refine_event_time',
    'start_time_range',
    'refine_status_code',
    'refine_source',
    'refine_target'
  ],
};

const interactiveIdsRaw = [
  'refine_event_time',
  'start_time_range',
  'refine_status_code',
  'refine_source',
  'refine_target'
];

const FailedRuns = () => {
  return (
    <ReactiveComponent
      componentId='failed-run-counts'
      defaultQuery={() => ({
        query: {
          bool: {
            must: [
              {
                exists: {
                  field: 'data.reindex.result.meta.statusCode',
                },
              },
            ],
          },
        },
        aggs: {
          data: {
            terms: {
              field: 'data.reindex.result.meta.statusCode',
              size: 100
            }, // Use the global aggregation
          },
        },
        size: 0,
      })
      }
      showFilter
      react={interactiveIds}
      render={({ aggregations }) => {
        let failedCount = 0;
        map(
          get(aggregations, 'data.buckets'),
          (b) => {
            failedCount = failedCount + b.doc_count;
          }
        )
        return (
          <span className='failed-runs'>
            {failedCount}
          </span>
        );
      }}
    />
  )
}

const PassedRuns = () => {
  return (
    <ReactiveComponent
      componentId='passed-run-counts'
      defaultQuery={() => ({
        query: {
          bool: {
            must_not: {
              exists: {
                field: 'data.reindex.result.name',
              },
            },
          },
        },
        aggs: {
          total_documents: {
            value_count: {
              field: '_index'
            }
          },
        },
        size: 0,
      })
      }
      showFilter
      react={interactiveIds}
      render={({ aggregations }) => {
        let passedCount = aggregations && aggregations.total_documents ? aggregations.total_documents.value : 0;
        return (
          <span className='passed-runs'>
            {passedCount}
          </span>
        );
      }}
    />
  )
}

export default function ConnectorProcess(props) {
  const reactiveProps = useReactiveProps('carwatch-monitoring');
  if (!reactiveProps) return <div style={{ textAlign: "center" }}>Please Login</div>;

  return (
    <div>
      <ReactiveBase {...reactiveProps} theme={theme}>
        <div className="container-fluid my-2">
          <div className="row g-2">
            <div className="col-2">
              <div className="p-1 bg-white">
                <ErrorBoundary>
                  <DynamicRangeSlider
                    componentId="refine_event_time"
                    dataField="@timestamp"
                    title="Select Event Time (yyyy-mm-dd)"
                    showHistogram={true}
                    rangeLabels={(min, max) => {
                      return {
                        start: dateformat(min, 'dd.mm.yyyy'),
                        end: dateformat(max, 'dd.mm.yyyy'),
                      };
                    }}
                    filterLabel='Timestamp'
                  />
                  <DateRange
                    componentId="start_time_range"
                    URLParams
                    showFilter
                    dataField="@timestamp"
                    filterLabel="Timestamp Range"
                  />
                  <hr />
                  <MultiList
                    componentId="refine_status_code"
                    dataField="data.reindex.result.meta.statusCode"
                    title="Error Status Code"
                    showCount
                    showFilter
                    showSearch={false}
                    loader={<Spinner animation="border" size="sm" />}
                    URLParams
                    innerClass={{
                      title: 'fw-bold',
                    }}
                    className="mt-1"
                    filterLabel="Error Status Code"
                    react={{
                      and: without(interactiveIdsRaw, 'refine_status_code'),
                    }}
                  />
                  <hr />
                  <MultiList
                    componentId="refine_source"
                    dataField="data.reindex.source.keyword"
                    title="Source Index"
                    showCount
                    showFilter
                    showSearch={false}
                    placeholder="Search Source Index..."
                    loader={<Spinner animation="border" size="sm" />}
                    URLParams
                    innerClass={{
                      title: 'fw-bold',
                    }}
                    className="mt-1"
                    filterLabel="Source Index"
                    renderItem={(label, count, isSelected) => {
                      const updatedLabel = SourceMappings[label] ? SourceMappings[label] : label;

                      return (
                        <div>
                          {updatedLabel}
                          <span style={{
                            marginLeft: 5
                          }}>
                            {count}
                          </span>
                        </div>
                      );
                    }}
                    react={{
                      and: without(interactiveIdsRaw, 'refine_source'),
                    }}
                  />
                  <hr />
                  <MultiList
                    componentId="refine_target"
                    dataField="data.reindex.target.keyword"
                    title="Target Index"
                    showCount
                    showFilter
                    showSearch={true}
                    placeholder="Search Target Index..."
                    loader={<Spinner animation="border" size="sm" />}
                    URLParams
                    innerClass={{
                      title: 'fw-bold',
                    }}
                    className="mt-1"
                    filterLabel="Target Index"
                    react={{
                      and: without(interactiveIdsRaw, 'refine_target'),
                    }}
                  />
                </ErrorBoundary>
              </div>
            </div>
            <div className="col-10">
              <div className="p-1 bg-white">
                <SelectedFilters className="mb-2" />
                <div className="row g-2">
                  <div className='result-summary'>
                    <div className="col-3">
                      <h6 className='failed-count'>Total Failed Runs: <FailedRuns /></h6>
                    </div>
                    <div className="col-3">
                      <h6 className='passed-count'>Total Successful Runs: <PassedRuns /> </h6>
                    </div>
                  </div>
                </div>
                <hr />
                <Tabs defaultActiveKey="charts" className="mb-2">
                  <Tab eventKey="charts" title="Charts">
                    <ConnectorProcessCharts componentId="Charts" interactiveIds={interactiveIds} />
                  </Tab>
                  <Tab eventKey="passed-details" title="Successful Run Details">
                    <ConnectorProcessPassedDetails interactiveIds={interactiveIds} />
                  </Tab>
                  <Tab eventKey="failed-details" title="Failed Run Details">
                    <ConnectorProcessFailedDetails interactiveIds={interactiveIds} />
                  </Tab>
                  <Tab eventKey="inactive-details" title="Connector Details">
                    <ConnectorProcessInactiveDetails />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </ReactiveBase>
    </div>
  )
}