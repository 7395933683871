import {
    ReactiveList
} from '@appbaseio/reactivesearch';
import ErrorBoundary from '../../ErrorBoundary';
import '../CSS/ConnectorProcessPassedDetails.css'
import SourceMappings from '../Constants/SourceMappings';

const PassedConnectorTable = ({ componentId, interactiveIds }) => {
    return (
        <div className="table-outer-container">
            <ReactiveList
                componentId={componentId}
                react={interactiveIds}
                dataField="@timestamp"
                size={100}
                defaultQuery={() => ({
                    query: {
                        bool: {
                            must_not: {
                                exists: {
                                    field: 'data.reindex.result.name',
                                },
                            },
                        },
                    }
                })}
                sortOptions={[
                    { dataField: '@timestamp', sortBy: 'desc', label: 'Descending' },
                    { dataField: '@timestamp', sortBy: 'asc', label: 'Ascending' },
                ]}
            >
                {({ data }) => (
                    <div className="table-container">
                        <table className="custom-table-passed">
                            <thead>
                                <tr>
                                    <th>Timestamp</th>
                                    <th>Data Source</th>
                                    <th>Target Index</th>
                                    <th>New Documents</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map(item => {
                                    const timestamp = new Date(item['@timestamp']);
                                    const formattedTimestamp = timestamp.toLocaleString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit',
                                    });

                                    return (
                                        <tr key={item._id}>
                                            <td>{formattedTimestamp}</td>
                                            <td>{SourceMappings[item.data.reindex.source]?SourceMappings[item.data.reindex.source]:item.data.reindex.source}</td>
                                            <td>{item.data.reindex.target}</td>
                                            <td>
                                                {item.data.reindex.result && item.data.reindex.result.created ? (
                                                    item.data.reindex.result.created
                                                ) : (
                                                    "0" // Or any default value/message when 'created' is not available
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>

                )}
            </ReactiveList>
        </div>
    )
}

const ConnectorProcessPassedDetails = ({ interactiveIds }) => {
    return (
        <div>
            <ErrorBoundary>
                <PassedConnectorTable componentId="PassedTable" interactiveIds={interactiveIds} />
            </ErrorBoundary>
        </div>
    )
}

export default ConnectorProcessPassedDetails;