const SourceMappings = {
    "cw_src_accc_recalls": "Australia - Recalls",
    "cw_src_accp_recalls": "Asean - Recalls",
    "cw_src_adac_recalls": "Germany (alt.) - Recalls",
    "cw_src_china_bulletins": "China - Bulletins",
    "cw_src_china_complaints": "China - Complaints",
    "cw_src_china_recalls": "China - Recalls",
    "cw_src_dpac_recalls": "DPAC - Recalls",
    "cw_src_india_recalls": "India - Recalls",
    "cw_src_ireland_recalls": "Ireland - Recalls",
    "cw_src_japanese_recalls": "Japan - Recalls",
    "cw_src_kba_recalls": "Germany - Recalls",
    "cw_src_korea_recalls": "Korea - Recalls",
    "cw_src_mlit_complaints": "Japan (alt) - Complaints",
    "cw_src_mlit_recalls": "Japan (alt) - Complaints",
    "cw_src_mlit_recalls_2023": "Japan (2023) - Recalls",
    "cw_src_nhtsa_bulletins": "US - Bulletins",
    "cw_src_nhtsa_complaints": "US - Complaints",
    "cw_src_nhtsa_investigations": "US - Investigations",
    "cw_src_nhtsa_recall_attachments": "US - Attachments",
    "cw_src_nhtsa_recalls": "US - Recalls",
    "cw_src_nz_recalls": "New Zealand - Recalls",
    "cw_src_rapex_recalls": "Rapex - Recalls",
    "cw_src_socialweb_allpar": "Allpar - Forum",
    "cw_src_socialweb_autoplenum": "Autoplenum - Forum",
    "cw_src_socialweb_autotreff": "Autotreff - Forum",
    "cw_src_socialweb_avengerforumz": "Avenger - Forum",
    "cw_src_socialweb_benzworld": "Benzworld - Forum",
    "cw_src_socialweb_bimmerfest": "Bimmerfest - Forum",
    "cw_src_socialweb_bmwsyndikat": "BMW Syndikat - Forum",
    "cw_src_socialweb_chryslerforum": "Chrysler - Forum",
    "cw_src_socialweb_dbforum": "DB - Forum",
    "cw_src_socialweb_dodgedurango": "Dodge Durango - Forum",
    "cw_src_socialweb_jeepgarage": "Jeep garage - Forum",
    "cw_src_socialweb_mbworld": "MB World - Forum",
    "cw_src_socialweb_motortalk": "Motortalk - Forum",
    "cw_src_socialweb_speakev": "SpeakEV - Forum",
    "cw_src_socialweb_vwvortex": "VW Vortex - Forum",
    "cw_src_socialweb_vwvortex_partial": "VW Vortex Partial - Forum",
    "cw_src_socialweb_xcarforum": "XCar - Forum",
    "cw_src_tc_investigations": "Canada - Investigations",
    "cw_src_tc_recalls": "Canada - Recalls",
    "cw_src_uae_recalls": "UAE - Recalls",
    "cw_src_uk_recalls": "UK - Recalls",
    "cw3_dev_nhtsa_bulletins": "[CW3][Dev] US  - Bulletins",
    "cw3_dev_nhtsa_complaints": "[CW3][Dev] US - Complaints",
    "cw3_dev_nhtsa_recalls": "[CW3][Dev] US - Recalls",
    "cw3_src_nhtsa_bulletins": "[CW3] US - Bulletins",
    "cw3_src_nhtsa_investigations": "[CW3] US - Investigations",
    "cw3_src_nhtsa_recalls": "[CW3] US - Recalls"
}

export default SourceMappings;