import {
    ReactiveComponent
} from '@appbaseio/reactivesearch';
import { useState } from 'react';
import ErrorBoundary from '../../ErrorBoundary';
import { map, get } from 'lodash';
import '../CSS/ConnectorProcessInactiveDetails.css'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/themes/lara-light-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import SourceMappings from '../Constants/SourceMappings';

const source_master = ['cw_src_accc_recalls', 'cw_src_accp_recalls', 'cw_src_adac_recalls',
    'cw_src_china_bulletins', 'cw_src_china_complaints', 'cw_src_china_recalls',
    'cw_src_dpac_recalls', 'cw_src_india_recalls', 'cw_src_ireland_recalls',
    'cw_src_japanese_recalls', 'cw_src_kba_recalls', 'cw_src_korea_recalls',
    'cw_src_mlit_complaints', 'cw_src_mlit_recalls', 'cw_src_mlit_recalls_2023',
    'cw_src_nhtsa_bulletins', 'cw_src_nhtsa_complaints', 'cw_src_nhtsa_investigations',
    'cw_src_nhtsa_recall_attachments', 'cw_src_nhtsa_recalls', 'cw_src_nz_recalls',
    'cw_src_rapex_recalls', 'cw_src_tc_investigations', 'cw_src_tc_recalls',
    'cw_src_uae_recalls', 'cw_src_uk_recalls', 'cw3_src_nhtsa_bulletins',
    'cw3_src_nhtsa_investigations', 'cw3_src_nhtsa_recalls']

    

function formatDate(timestamp) {
    if (!timestamp) {
        return ''; // Handle cases where timestamp is undefined or null
    }

    const date = new Date(timestamp);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
}

function calculateDaysDifference(startDateStr, endDateStr) {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
    const timeDifference = endDate - startDate;
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
}

const InactiveConnectorTable = ({ componentId }) => {
    return (
        <div className="table-outer-container">
            <ReactiveComponent
                componentId={componentId}
                defaultQuery={() => ({
                    query: {
                        range: {
                            '@timestamp': {
                                gte: 'now-8d/d',
                                lte: 'now/d',
                            },
                        },
                    },
                    aggs: {
                        data: {
                            terms: {
                                field: 'data.reindex.source.keyword',
                                size: 50
                            },
                        },
                    },
                    size: 0,
                })
                }
                showFilter
                render={({ aggregations }) => {
                    let weekly_sources = [];
                    map(
                        get(aggregations, 'data.buckets'),
                        (b) => {
                            weekly_sources.push(b.key);
                        }
                    );
                    let missing_values = source_master.filter(value => !weekly_sources.includes(value));
                    const sources_length = missing_values.length;
                    if (missing_values.length === 0) {
                        missing_values.push('N/A');
                    }
                    return (
                        <div>
                            <table className='inactive-source-table'>
                                <thead>
                                    <tr>
                                        <th>Inactive Source Indices in last 8 days - {sources_length}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.from({ length: missing_values.length }, (_, index) => (
                                        <tr key={index}>
                                            <td>{missing_values[index]}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    );
                }}
            />
        </div>
    )
}

const ConnectorOverviewTable = ({ componentId }) => {
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    return (
        <div className="table-outer-container">
            <ReactiveComponent
                componentId={componentId}
                defaultQuery={() => ({
                    query: {
                        match_all: {},
                    },
                    aggs: {
                        data: {
                            terms: {
                                field: 'data.reindex.source.keyword',
                                size: 50
                            },
                            aggs: {
                                timestamps: {
                                    date_histogram: {
                                        field: '@timestamp',
                                        calendar_interval: '1d',
                                        order: {
                                            _key: 'desc'  // Add this line to sort by timestamp in descending order
                                        }
                                    },
                                    aggs: {
                                        new_documents: {
                                            sum: {
                                                field: 'data.reindex.result.created'
                                            }
                                        }
                                    }
                                }
                            }
                        },
                    },
                    size: 0,
                })
                }
                showFilter
                render={({ aggregations }) => {
                    let values = [];
                    map(
                        get(aggregations, 'data.buckets'),

                        (b) => {
                            let item = {}
                            //item.source = b.key;
                            item.source = SourceMappings[b.key]?SourceMappings[b.key]:b.key;
                            //console.log(SourceMappings[b.key]?SourceMappings[b.key]:"NONE");
                            if (b && b.timestamps && b.timestamps.buckets) {
                                for (let i = 0; i < b.timestamps.buckets.length; i++) {
                                    const timestamp = b.timestamps.buckets[i];
                                    if (i === 0) {
                                        item.latest_timestamp = formatDate(timestamp.key);
                                    }
                                    if (timestamp && timestamp.new_documents) {
                                        if (timestamp.new_documents.value > 0) {
                                            item.last_added_on = formatDate(timestamp.key);
                                            item.last_added = timestamp.new_documents.value;
                                            item.ndays = calculateDaysDifference(item.last_added_on, item.latest_timestamp);
                                            values.push(item);
                                            break;
                                        }
                                    }
                                }
                            }

                        }
                    );

                    const getRowClassName = (rowData) => {
                        // Customize this condition based on your requirement
                        const shouldApplyColor = rowData.ndays > 8; // Adjust the condition for 'ndays'
                        return shouldApplyColor ? 'red-row' : 'green-row';
                    };

                    return (
                        <div>
                            <InputText
                                placeholder="Search Source"
                                onInput={(e) =>
                                    setFilters({
                                        global: { value: e.target.value, matchMode: FilterMatchMode.CONTAINS },
                                    })
                                }
                            />
                            <DataTable
                                value={values}
                                rowClassName={getRowClassName}
                                filters={filters}
                            >
                                <Column field='source' header='Source' sortable />
                                <Column className='format-cell' field='latest_timestamp' header='Last Indexed On' sortable />
                                <Column className='format-cell' field='last_added_on' header='Documents last added on' sortable />
                                <Column className='format-cell' field='ndays' header='New documents added before (Days)' sortable />
                                <Column field='last_added' header='Latest added document count' sortable />
                            </DataTable>
                        </div>
                    );
                }}
            />
        </div>
    )
}

const ConnectorProcessInactiveDetails = () => {
    return (
        <div>
            <div><h6 className='h6'>***Please note that filters do not apply on this page as it's a static data page!***</h6></div>
            <ErrorBoundary>
                <InactiveConnectorTable componentId="InactiveTable" />
            </ErrorBoundary>
            <hr />
            <ErrorBoundary>
                <ConnectorOverviewTable componentId="ConnectorInfoTable" />
            </ErrorBoundary>
        </div>
    )
}

export default ConnectorProcessInactiveDetails;