import {
    ReactiveList
} from '@appbaseio/reactivesearch';
import ErrorBoundary from '../../ErrorBoundary';
import '../CSS/ConnectorProcessFailedDetails.css'
import SourceMappings from '../Constants/SourceMappings';

const FailedConnectorTable = ({ componentId, interactiveIds }) => {
    return (
        <div className="table-outer-container">
            <ReactiveList
                componentId={componentId}
                react={interactiveIds}
                dataField="@timestamp"
                size={100}
                defaultQuery={() => ({
                    query: {
                        exists: {
                            field: 'data.reindex.result.name',
                        },
                    }
                })}
                sortOptions={[
                    { dataField: '@timestamp', sortBy: 'desc', label: 'Descending' },
                    { dataField: '@timestamp', sortBy: 'asc', label: 'Ascending' },
                ]}
            >
                {({ data }) => (
                    <div className="table-container">
                        <table className="custom-table-failed">
                            <thead>
                                <tr>
                                    <th>Timestamp</th>
                                    <th>Data Source</th>
                                    <th>Target Index</th>
                                    <th>Status</th>
                                    <th>Status Code</th>
                                    <th>Error Name</th>
                                    <th>Attempts</th>
                                    <th>Dead Counts</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map(item => {
                                    const timestamp = new Date(item['@timestamp']);
                                    const formattedTimestamp = timestamp.toLocaleString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit',
                                    });
                                    return (
                                        <tr key={item._id}>
                                            <td>{formattedTimestamp}</td>
                                            <td>{SourceMappings[item.data.reindex.source]?SourceMappings[item.data.reindex.source]:item.data.reindex.source}</td>
                                            <td>{item.data.reindex.target}</td>
                                            <td>{item.data.reindex.result.meta.meta.connection.status}</td>
                                            <td>{item.data.reindex.result.meta.statusCode}</td>
                                            <td>{item.data.reindex.result.name}</td>
                                            <td>{item.data.reindex.result.meta.meta.attempts}</td>
                                            <td>{item.data.reindex.result.meta.meta.connection.deadCount}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>

                )}
            </ReactiveList>
        </div>
    )
}

const ConnectorProcessFailedDetails = ({ componentId, interactiveIds }) => {
    return (
        <div>
            <ErrorBoundary>
                <FailedConnectorTable componentId="FailedTable" interactiveIds={interactiveIds} />
            </ErrorBoundary>
        </div>
    )
}

export default ConnectorProcessFailedDetails;