
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuth0 } from '@auth0/auth0-react';
import { PageLoader } from './components/PageLoader';
import { Container, Nav, Navbar, Row } from 'react-bootstrap';
import { LoginButton } from './components/Buttons/LoginButton';
import { LogoutButton } from './components/Buttons/LogoutButton';
import { Route, Routes } from 'react-router-dom';
import ConnectorProcess from './pages/ConnectorProcess';
import { useReactiveProps } from './ReactiveProps';

function App() {
  const reactiveProps = useReactiveProps('irel40_raw_kulim');
  const { isAuthenticated, isLoading } = useAuth0();
  if (isLoading) return <PageLoader />;

  return (
    <>
      <Container fluid className="border-bottom bg-white">
        <Row className="g-2">
          <Navbar className="col py-0">
            <Navbar.Brand href="/" target="_blank">
              <img
                src="plexalytics_logo.png"
                className="d-inline-block align-top"
                alt="plexalytics Logo"
                height="50"
              />
            </Navbar.Brand>
            <Nav className="me-auto">
              {isAuthenticated && (
                <>
                  <Nav.Link href="/">Connector Process Logs</Nav.Link>
                </>)}
            </Nav>
            <Nav>
              {reactiveProps && <LogoutButton />}
              {!reactiveProps && <LoginButton />}
            </Nav>
          </Navbar>
        </Row>
      </Container>
      <Routes>
        <Route index element={<ConnectorProcess />} />
      </Routes>
    </>
  );
}

export default App;
